import React from 'react';

import GatsbyImage from 'gatsby-image';
import {
  Layout,
  SEO,
  Hero,
  HeroHeading,
  Heading,
  ListItem,
  BlueLink,
  ServicesGrid,
  TestimonialSlider,
  Contact,
} from '../components';
import { useGraphQL } from '../hooks';

function IndexPage() {
  return (
    <Layout>
      <SEO title="Home" />
      <HeroSection />
      <ServicesIntro />
      <div className="pt-24">
        <ServicesGrid />
      </div>
      {/* <TestimonialSlider /> */}
      <Contact
        bgColorClass="bg-brand-yellow"
        textColorClass="text-black"
        subHeading="In need of an electrician?"
      />
    </Layout>
  );
}

function HeroSection() {
  const { homeHeroImage, accreditedMasterElectrician } = useGraphQL();
  return (
    <Hero bgImage={homeHeroImage.childImageSharp.fluid}>
      <HeroHeading heading={['Do it once, ', 'do it right']} />
      <ul className="mt-4 space-y-1">
        <ListItem>All Types Of Level 2 Electrical Work</ListItem>
        <ListItem>Friendly Knowledgeable Electricians</ListItem>
        <ListItem>Prompt And Professional</ListItem>
        <ListItem>Trusted And Reliable</ListItem>
        <ListItem>Free Quotes</ListItem>
        <li className="flex">
          <span className="sr-only">Master Electrician</span>
          <div className="p-2 mt-4 overflow-hidden bg-white rounded-md shadow">
            <GatsbyImage
              fluid={accreditedMasterElectrician.childImageSharp.fluid}
              imgStyle={{ objectFit: 'contain' }}
              className="w-72"
            />
          </div>
        </li>
        <p className="pt-4 font-bold text-white">
          Licence No. 307061C | ABN: 90 643 761 451
        </p>
      </ul>
    </Hero>
  );
}

function ServicesIntro() {
  const { homeHeroTwoImage } = useGraphQL();
  return (
    <article className="grid md:grid-cols-2">
      <div className="px-4 pb-24 sm:px-8 lg:px-20">
        <Heading
          heading={['YOUR LOCAL', 'LEVEL 2 ACCREDITED', 'SERVICE PROVIDER']}
          tag="h2"
        />
        <div className="pt-8 pb-12 prose text-white">
          <p>
            Pacific Blue Electrical are Essential Energy accredited and can
            perform Level 2 electrical works within Essential Energy's footprint
            area. We are based in Port Macquarie and conduct Level 2 works
            throughout the Mid North Coast.
          </p>
          <p>
            We specialise in all aspects of Level 2 electrical from new
            installations to alterations and defect rectifications. We listen to
            what you want, discuss available options and suggest the best
            solution for your needs.
          </p>
        </div>
        {/* <BlueLink to="/services/" text="Find out more here" /> */}
      </div>
      <div>
        <GatsbyImage
          fluid={homeHeroTwoImage.childImageSharp.fluid}
          className="object-cover object-center h-full"
        />
      </div>
    </article>
  );
}

export default IndexPage;
